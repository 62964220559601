<template>
   <footer id="foter-wrapper" class="container">
      <div class="row">
         <div class="footer-col-wrapper col-6 col-md-4">
            <h5 class="footer-header">{{ $t("footer.contactUs") }}</h5>
            <section id="germanHeadOffice" class="footer-contact-container">
               <h6 class="footer-header-sub">{{ $t("footer.germanHead") }}</h6>
               <p class="footer-head-details">
                  {{ $t("footer.germanHeadDetails_1") }}
               </p>
               <p class="footer-head-details">
                  {{ $t("footer.germanHeadDetails_2") }}
               </p>
               <p class="footer-head-phone">
                  <a href="tel:">{{ $t("footer.germanHeadPhone") }}</a>
               </p>
               <p class="footer-head-mail">
                  <a href="tel:">{{ $t("footer.germanHeadMail") }}</a>
               </p>
            </section>
            <section id="turkeyDmcOffice" class="footer-contact-container">
               <h6 class="footer-header-sub">{{ $t("footer.turkeyHead") }}</h6>
               <p class="footer-head-details">
                  {{ $t("footer.turkeyHeadDetails_1") }}
               </p>
               <p class="footer-head-details">
                  {{ $t("footer.turkeyHeadDetails_2") }}
               </p>
               <p class="footer-head-phone">
                  <a href="tel:">{{ $t("footer.turkeyHeadPhone") }}</a>
               </p>
            </section>
         </div>
         <div class="footer-col-wrapper col-6 col-md-4">
            <h5 class="footer-header">{{ $t("footer.quickJump") }}</h5>
            <nav id="footer-quickjump">
               <router-link to="/privacy-policy">Datenschutz</router-link>
               <router-link to="/agb">AGB</router-link>
               <router-link to="/impressum">Impressum</router-link>
            </nav>
         </div>
      </div>

      <!-- footer row 2-->
      <div class="row">
         <div class="footer-col-wrapper col-6 col-md-4">
            <span class="copyright-text">&copy; {{ thisYear }}, Global Travel Services GmbH</span>
         </div>
         <div class="footer-col-wrapper col-6 col-md-4">
            <section class="follow-us-icon-wrapper">
               <a href="https://www.facebook.com/gtsfly" target="_blank" class="follow-us-icons">
                  <inline-svg
                     :src="require('@/assets/img/ico_facebook.svg')"
                     width="24"
                     height="24"
                     fill="#202A38"
                     aria-label="Facebook"></inline-svg>
               </a>
               <a href="https://www.instagram.com/gtsfly/" target="_blank" class="follow-us-icons">
                  <inline-svg
                     :src="require('@/assets/img/ico_instagram.svg')"
                     width="24"
                     height="24"
                     fill="#202A38"
                     aria-label="Instagram"></inline-svg>
               </a>
               <a href="https://www.linkedin.com/company/gtsfly/" target="_blank" class="follow-us-icons">
                  <inline-svg
                     :src="require('@/assets/img/ico_linkedin.svg')"
                     width="24"
                     height="24"
                     fill="#202A38"
                     aria-label="Linkedin"></inline-svg>
               </a>
            </section>
         </div>
      </div>
   </footer>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import moment from "moment";
export default {
   name: "FooterMenu",
   components: {
      InlineSvg,
   },
   data() {
      return {
         thisYear: moment().year(),
      };
   },
};
</script>

<style lang="scss" scoped>
#foter-wrapper {
   // background-color: red;
   .footer-col-wrapper {
      text-align: left;
      margin-bottom: 5rem;
      color: #202a38;

      p,
      h6,
      h5 {
         margin: 0;
      }
      .footer-header {
         font-size: 28px;
         font-weight: 600;
         margin-bottom: 1.5rem;
      }
      .footer-contact-container {
         margin-bottom: 2rem;

         .footer-header-sub {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0.75rem;
         }
         .footer-head-details {
            font-size: 16px;
            margin: 0.2rem 0;
         }

         .footer-head-phone,
         .footer-head-mail {
            margin-top: 0.25rem;
         }
         .footer-head-phone {
            margin-top: 0.5rem;
         }
      }

      #footer-quickjump {
         a {
            display: block;
            text-decoration: underline;
            padding: 0.7rem 0;
            font-size: 16px;

            &:first-child {
               padding-top: 0;
            }
            &:last-child {
               padding-bottom: 0;
            }
         }
      }
      .copyright-text {
         color: #737880;
         font-size: 14px;
         display: flex;
         align-items: center;
         height: 28px;
      }
      .follow-us-icon-wrapper {
         .follow-us-icons {
            margin: 0 0.5rem;

            &:first-child {
               margin-left: 0;
            }
            &:last-child {
               margin-right: 0;
            }

            svg {
               transform: scale(1);
               transition-property: transform;
               transition-timing-function: ease-in;
               transition-duration: 0.05s;

               &:hover {
                  transform: scale(1.2);
               }

               path {
                  fill: blue;
               }
            }
         }
      }

      a {
         color: #202a38;

         &:hover {
            color: #216dd9;
         }
      }
   }
}
</style>
