<template>
   <div id="app">
      <div class="transition-wrapper">
         <span class="fader one"></span>
         <span class="fader two"></span>
         <span class="fader three"></span>
      </div>
      <CookiePopup />
      <ApplicationForm v-if="ApplicationForm" />
      <HeaderMenu v-if="true" />
      <!-- Empty space for fixed header menu -->
      <div style="height: 100px !important"></div>
      <!-- <nav><router-link to="/">Home</router-link></nav> -->
      <transition name="fade" mode="out-in">
         <router-view />
      </transition>
      <FooterMenu />
   </div>
</template>

<script>
import CookiePopup from "@/components/common/Cookie.vue";
import HeaderMenu from "@/components/structural/HeaderMenu.vue";
import FooterMenu from "@/components/structural/FooterMenu.vue";
import ApplicationForm from "@/components/unitary/ApplicationForm.vue";
import router from "./router";
import gsap from "gsap";
// import SplashScreen from "@/components/unitary/SplashScreen.vue";

export default {
   components: {
      HeaderMenu,
      FooterMenu,
      ApplicationForm,
      CookiePopup,
      // SplashScreen,
   },

   computed: {
      CookiePopup() {
         const self = this;
         self;
         return self.$store.state.cookieAllowance;
         // return false;
      },
      ApplicationForm() {
         const self = this;
         return self.$store.state.applicationForm;
      },
   },
   created() {
      const self = this;
      self;
      router.beforeResolve((to, from, next) => {
         // console.log(`Before resolve:`);
         this.handlerPageAnimationOpen();
         // console.log(`Route to`, to);
         // console.log(`Route from`, from);

         setTimeout(() => {
            next();
         }, 1000);
         // self.handlerPageAnimationClose();
      });

      // this.$i18n.locale = "de";
   },
   mounted() {},
   methods: {
      handlerPageAnimationOpen() {
         const self = this;
         const t = gsap.timeline();
         t.fromTo(
            ".fader",
            {
               opacity: 1,
               width: 0,
            },
            {
               opacity: 1,
               width: "100%",

               duration: 1,
               ease: "expo.inOut",
               stagger: {
                  each: 0.125,
                  ease: "power.out",
               },
               onStart() {
                  document.querySelector(".transition-wrapper").style.zIndex = "9999";
                  // gsap.set(this.targets(), { clearProps: "transform" });
               },
               onComplete() {
                  self.handlerPageAnimationClose();
                  // self.handlerPageAnimationOpen();

                  console.log("handlerPageAnimationOpen completed");
               },
            }
         );
      },
      handlerPageAnimationClose() {
         const t = gsap.timeline({ delay: 0.125 });
         t.to(".fader", {
            // opacity: 0,
            width: 0,
            duration: 1,
            ease: "expo.out",
            stagger: {
               each: 0.125,
               ease: "power.out",
            },
            onComplete() {
               document.querySelector(".transition-wrapper").style.zIndex = "-1";
               console.log("handlerPageAnimatioClose completed");
            },
         });
      },
   },
   watch: {
      "$store.state.applicationForm": {
         handler: function () {
            console.log("koko");
         },
      },
      "$store.state.cookieAllowance": {
         handler: function (value) {
            value;
         },
      },
   },
};
</script>

<style lang="scss">
body {
   // background-color: coral !important;
}
#app {
   margin: 0;
   width: 100vw;
}

.transition-wrapper {
   position: fixed;
   inset: 0 0 0 0;
   z-index: -1;
   // background: gray;
}
.fader {
   // opacity: 1;

   // height: 50px;
   // width: 100px;
   // position: fixed;
   // top: 0;
   // bottom: 0;
   // // z-index: -1;
   // z-index: 9999;
   // // clip-path: circle(50px at 0 100px);
   position: fixed;
   top: 0;
   bottom: 0;
   display: block;
   // width: 20px;
   height: 100%;
   &.one {
      background: #2268db;
   }
   &.two {
      background: #00c3ff;
   }
   &.three {
      background: #2dd5ff;
   }
   &.four {
      background: #94eaff;
   }
   &.three {
      background: #94eaff;
   }
}
</style>
