import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";
import Contact from "../pages/Contact.vue";
import Products from "../pages/Products.vue";

import DatenschutzPage from "@/pages/DatenschutzPage.vue";
import AGBPage from "@/pages/AGBPage.vue";
import ImpressumPage from "@/pages/ImpressumPage.vue";

import About from "../pages/AboutUs.vue";
import StaticPages from "../pages/StaticPages";
import News from "../pages/News";

Vue.use(VueRouter);

const routes = [
   {
      path: "/",
      name: "home",
      component: Home,
   },
   {
      path: "/contact",
      name: "contact",
      component: Contact,
   },

   {
      path: "/about-us",
      name: "about",
      component: About,
   },
   //  {
   //     path: "/privacy-policy",
   //     name: "210",
   //     component: StaticPages,
   //  },
   {
      path: "/privacy-policy",
      name: "210",
      component: DatenschutzPage,
   },
   {
      path: "/impressum",
      name: "210",
      component: ImpressumPage,
   },
   {
      path: "/agb",
      name: "210",
      component: AGBPage,
   },
   {
      path: "/cookie-policy",
      name: "216",
      component: StaticPages,
   },
   {
      path: "/products",
      name: "product",
      component: Products,
   },
   {
      path: "/news/:slug",
      name: "news",
      component: News,
   },
];

const router = new VueRouter({
   mode: "history",
   base: process.env.BASE_URL,
   routes,
});

export default router;
